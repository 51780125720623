import { supabase } from '@/lib/supabaseClient'
import { useQuery } from '@tanstack/vue-query'
import router from '@/router'

export function useCrewAssociatedDocsQuery(profileId: string) {
  const workspaceId = router.currentRoute.value.params['workspaceId']
  return useQuery({
    queryKey: ['profiles', profileId, 'associatedDocs'],
    queryFn: async () => {
      return supabase
        .from('DocumentsCrewProfiles')
        .select(`documentId, crewProfileId, document:Documents!inner(*)`)
        .eq('workspaceId', workspaceId)
        .eq('crewProfileId', profileId)
        .is('document.archivedAt', null)
    },
    select: ({ data }) => new Map(data?.map(({ document }) => [document!.id, document]))
  })
}
