import CompleteDocumentModal from '@/components/CompleteDocumentModal.vue'
import MultipleFilesModal from '@/components/MultipleFilesModal.vue'
import PageEditorModal from '@/components/PageEditorModal.vue'
import { useModalStore } from '@/stores/modal'

export default async function (
  files: File[],
  {
    allowMultiple,
    crewProfileId,
    workspaceId
  }: { allowMultiple?: boolean; crewProfileId?: string; workspaceId?: string }
) {
  if (!files.length) return
  const { presentModal, presentAlert } = useModalStore()

  if (files.some(({ type }) => !/image|pdf/.test(type))) {
    presentAlert({
      title: 'Unsupported file type',
      message: 'Please make sure you are uploading an image or PDF file.'
    })
    return
  }

  if (!!files[1] && allowMultiple) {
    const { combinePages } = await presentModal({
      component: MultipleFilesModal,
      componentProps: { files }
    })
    if (!combinePages) return
  }

  const completeDocumentModalProps = await presentModal({
    component: PageEditorModal,
    componentProps: { files, crewProfileId, workspaceId }
  })
  if (!!completeDocumentModalProps) {
    return presentModal({
      component: CompleteDocumentModal,
      componentProps: completeDocumentModalProps
    })
  }
}
