import router from '@/router'
import { QueryClient, useQuery } from '@tanstack/vue-query'
import axios from 'axios'

const queryClient = new QueryClient()

export function useWorkspaceQuery(workspaceId?: string) {
  workspaceId = workspaceId ?? (router.currentRoute.value.params['workspaceId'] as string)
  return useQuery({
    queryKey: ['workspaces', workspaceId],
    staleTime: 1000,
    queryFn: () =>
      axios.get('', {
        baseURL: `${import.meta.env.VITE_API_BASE_URL}/w/${workspaceId}`
      }),
    select: ({ data }: any) => data,
    placeholderData: () => {
      return {
        data: queryClient
          .getQueryData<{ data: { items: any[] } }>(['workspaces'])
          ?.data.items.find((workspace: any) => workspace.id === workspaceId)
      }
    }
  })
}
