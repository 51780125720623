<template>
  <button
    type="button"
    :disabled="disabled"
    @click.stop="$emit('clicked')"
    :class="[baseClasses, classes[color], full ? 'w-full' : '', disabled ? 'opacity-60' : '']"
  >
    <slot></slot>
  </button>
</template>
<script setup lang="ts">
defineEmits(['clicked'])
withDefaults(
  defineProps<{
    color?: 'primary' | 'blue' | 'danger' | 'light' | 'green'
    full?: boolean
    disabled?: boolean
  }>(),
  { color: 'primary', full: false, disabled: false }
)
const baseClasses =
  'cursor-pointer text-center rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm flex items-center justify-center gap-x-2'
const classes = {
  primary: `bg-primary-600 text-white hover:bg-primary-500`,
  blue: `bg-blue-600 text-white hover:bg-blue-500`,
  green: `bg-lime-500 text-white hover:bg-lime-400`,
  light: `bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50`,
  danger: `bg-red-600 text-white hover:bg-red-500`
}
</script>
