<template>
  <div v-if="!!doc" class="rounded-lg bg-white shadow">
    <div
      v-if="doc.status === 'COMPLETE'"
      :title="doc.name || doc.certificateTypeId || undefined"
      class="flex w-full items-center justify-between space-x-5 p-6"
    >
      <div class="flex-1 overflow-hidden">
        <div class="flex items-center space-x-3">
          <h3
            class="text-sm font-medium text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden"
          >
            {{ doc.name || doc.certificateTypeId || 'none' }}
          </h3>
        </div>
        <p v-if="!!doc.archivedAt" class="mt-1 text-sm text-gray-500">
          Archived
          {{ formatDistance(new Date(doc.archivedAt), new Date(), { addSuffix: true }) }}
        </p>
        <p v-else class="mt-1 text-sm text-gray-500">
          Added
          {{ formatDistance(new Date(doc.createdAt), new Date(), { addSuffix: true }) }}
        </p>
      </div>
      <ExpiryIndicator
        data-testid="expiry-indicator"
        v-if="!!doc.expiryDate"
        :expiryDate="doc.expiryDate"
      ></ExpiryIndicator>
      <div v-else class="h-12"></div>
    </div>
    <div v-else class="flex w-full items-center justify-between space-x-5 p-6">
      <ion-spinner v-if="doc.status === 'UPLOADING'" class="h-5 w-5" />
      <DocumentCheckIcon v-else class="h-5 w-5 animate-bounce" />
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="truncate text-sm font-medium text-gray-900">
            {{ doc.status === 'UPLOADING' ? 'Uploading...' : 'Ready to complete' }}
          </h3>
        </div>
        <p class="mt-1 truncate text-sm text-gray-500">Added {{ createdAt }}</p>
      </div>
      <ExpiryIndicator
        data-testid="expiry-indicator"
        v-if="!!doc.expiryDate"
        :expiryDate="doc.expiryDate"
      ></ExpiryIndicator>
    </div>
  </div>
</template>

<script setup lang="ts">
import ExpiryIndicator from '@/components/ExpiryIndicator.vue'
import type { DocumentsTable } from '@/types/types'
import { DocumentCheckIcon } from '@heroicons/vue/24/outline'
import { formatDistance } from 'date-fns'
const props = defineProps<{
  doc?: DocumentsTable['Row']
}>()
const createdAt = !!props.doc
  ? formatDistance(new Date(props.doc.createdAt!), new Date(), { addSuffix: true })
  : null
</script>
