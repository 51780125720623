import { supabase } from '@/lib/supabaseClient'
import type { CreateDocumentPage, DocumentData } from '@/types/types'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'

export default async function createDoc(
  originalFiles: { file: File; skipUpload: boolean }[],
  pages: CreateDocumentPage[],
  workspaceId: string,
  crewProfileId?: string
) {
  const docId = uuidv4()
  const originals = originalFiles
    .filter(({ skipUpload }) => !skipUpload)
    .map(({ file }) => ({
      file,
      path: `${workspaceId}/${docId}/originals/${uuidv4()}`
    }))
  const imageFiles = await Promise.all(
    pages.map(async (page) => ({
      file: await imageCompression(page.edited, {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1500,
        useWebWorker: true
      }),
      path: `${workspaceId}/${docId}/images/${uuidv4()}`
    }))
  )

  const { data: uploadingDoc, error: uploadingDocError } = await supabase
    .from('Documents')
    .insert({
      id: docId,
      workspaceId,
      status: 'UPLOADING',
      imagePaths: imageFiles.map(({ path }) => path),
      originalFilePaths: originals.map(({ path }) => path)
    })
    .select()
    .single()
  if (uploadingDocError) throw uploadingDocError

  if (!!crewProfileId) {
    const { error: docRelationshipError } = await supabase.from('DocumentsCrewProfiles').insert({
      documentId: docId,
      documentWorkspaceId: workspaceId,
      workspaceId,
      crewProfileId
    })
    if (docRelationshipError) throw docRelationshipError
  }
  try {
    const originalUploads = Promise.all(
      originals.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
    )
    const imageUploads = Promise.all(
      imageFiles.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
    )

    await Promise.all([originalUploads, imageUploads])
  } catch (error) {
    console.log(error)
    alert(`Upload error, please try again.`)
    return
  }

  const { data: formattedDoc, error: updateError } = await supabase
    .from('Documents')
    .update({
      status: 'FORMATTED'
    })
    .eq('id', uploadingDoc.id)
    .select()
    .single()
  if (updateError) throw updateError
  return formattedDoc as DocumentData
}
