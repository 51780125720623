<template>
  <FormKit type="autocomplete" selection-removable="yes" :options="options as any" v-bind="props" />
</template>
<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import currencies from '@/data/currencies.json'

const options = currencies.map((currency) => ({
  label: `${currency.name} (${currency.code})`,
  value: currency.code
}))
const props = defineProps<{ [key: string]: any }>()
</script>
