<template>
  <!-- <div class="border border-gray-600 rounded-md p-4"> -->
  <FormKit ref="group" type="group" name="travelDoc" #default="{ value, node: { input } }">
    <FormKit type="hidden" name="docId" />
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
      <FormKit type="text" :disabled="!!value?.docId" label="Travel document type" name="type" />
      <FormKit
        type="text"
        :disabled="!!value?.docId"
        label="Travel document number"
        name="number"
      />
      <FormKit
        type="date"
        :disabled="!!value?.docId"
        label="Travel document expiry"
        :validation="`?date_after:${new Date().toISOString().split('T')[0]}`"
        :validationMessages="{ date_after: 'Document has expired!' }"
        name="expiry"
      />
      <div class="md:mt-5">
        <FormKit v-if="!value?.docId" @click="selectDoc()" type="button" label="Choose document" />
        <FormKit v-else type="button" label="Remove document" @click="input({})" />
      </div>
    </div>
  </FormKit>
  <!-- </div> -->
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useModalStore } from '@/stores/modal'
import SelectDocModal from './SelectDocModal.vue'
import { ref } from 'vue'

const { currentRoute } = useRouter()
const { presentModal } = useModalStore()

const group = ref()

const selectDoc = async () => {
  const result = await presentModal({
    component: SelectDocModal,
    componentProps: {
      crewProfileId: currentRoute.value.query.profile,
      title: `Select a travel document`
    }
  })
  const selectedDoc = result?.selectedDocs?.[0]
  if (!!selectedDoc) {
    console.log(group.value)
    group.value.node.input({
      type: selectedDoc.categoryId === 'PASSPORT' ? 'Passport' : 'ID',
      number: selectedDoc.documentRef,
      expiry: selectedDoc.expiryDate,
      docId: selectedDoc.id
    })
  }
}
</script>
