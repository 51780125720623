<template>
  <div>
    <div v-if="!!nameSuggestions.length">
      <div class="font-semibold text-xs mb-1.5">What do you want to name this document?</div>
      <div class="mb-3 flex flex-wrap gap-2">
        <div
          v-for="suggestion of nameSuggestions"
          :key="suggestion"
          class="inline-flex cursor-pointer items-center rounded-full py-1 px-3 text-sm font-medium"
          :class="[
            value.name === suggestion
              ? 'text-primary-700 bg-primary-200'
              : 'text-gray-700 bg-gray-200'
          ]"
          @click="setSuggestion(suggestion)"
        >
          {{ suggestion }}
        </div>
      </div>
      <FormKit
        type="text"
        label=""
        ref="nameInput"
        validation="required"
        name="name"
        help="This is how the document will appear in lists and profiles"
        placeholder="Try to be descriptive and concise..."
      ></FormKit>
    </div>
    <FormKit
      v-else
      type="text"
      label="What do you want to name this document?"
      validation="required"
      name="name"
      ref="nameInput"
      placeholder="Try to be descriptive and concise..."
    ></FormKit>
    <div class="grid grid-cols-2 gap-x-4">
      <FormKit type="date" name="issueDate" label="Issue date" placeholder="Select a date" />
      <FormKit type="date" name="expiryDate" label="Expiry date" placeholder="Select a date" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWorkspaceQuery } from '@/queries/workspace'
import { ref } from 'vue'
import { computed } from 'vue'
const props = defineProps<{
  value: any
}>()
const nameInput = ref()
const { data } = useWorkspaceQuery()
const nameSuggestions = computed(() => data.value?.docNameSuggestions?.OTHER ?? [])
const setSuggestion = (suggestion: string) => {
  if (props.value.name !== suggestion) {
    nameInput.value?.node.input(suggestion)
  } else {
    nameInput.value?.node.input('')
  }
}
</script>
