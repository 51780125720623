import router from '@/router'
import type { CreateDocumentPage, DocumentData } from '@/types/types'
import { v4 as uuidv4 } from 'uuid'
import imageCompression from 'browser-image-compression'
import { supabase } from '@/lib/supabaseClient'

export default async function (
  doc: Partial<DocumentData> & Pick<DocumentData, 'id' | 'originalFilePaths'>,
  originalFiles: { file: File; skipUpload: boolean }[],
  pages: CreateDocumentPage[]
) {
  const { currentRoute } = router
  const workspaceId = currentRoute.value.params.workspaceId

  const extraOriginalFiles = originalFiles
    .filter(({ skipUpload }) => !skipUpload)
    .map(({ file }) => ({
      file,
      path: `${workspaceId}/${doc.id}/originals/${uuidv4()}`
    }))
  const imageFiles = await Promise.all(
    pages.map(async (page) => ({
      file: await imageCompression(page.edited, {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 1500,
        useWebWorker: true
      }),
      path: `${workspaceId}/${doc.id}/images/${uuidv4()}`
    }))
  )

  const updatedDoc = await supabase
    .from('Documents')
    .update({
      imagePaths: imageFiles.map(({ path }) => path),
      originalFilePaths: [...doc!.originalFilePaths!, ...extraOriginalFiles.map(({ path }) => path)]
    })
    .match({ id: doc.id, workspaceId: router.currentRoute.value.params.workspaceId })
    .select()
    .single()
    .then(({ data }) => data)

  if (!!updatedDoc?.imagePaths?.length) {
    try {
      const originalUploads = Promise.all(
        extraOriginalFiles.map(({ file, path }) =>
          supabase.storage.from('documents').upload(path, file)
        )
      )
      const imageUploads = Promise.all(
        imageFiles.map(({ file, path }) => supabase.storage.from('documents').upload(path, file))
      )
      await Promise.all([originalUploads, imageUploads])
    } catch (error) {
      console.log(error)
      alert(`Upload error, please try again.`)
      return
    }

    const formattedDoc = await supabase
      .from('Documents')
      .update({
        status: 'FORMATTED'
      })
      .match({ id: doc.id, workspaceId: router.currentRoute.value.params.workspaceId })
      .select()
      .single()
      .then(({ data }) => data)

    return formattedDoc
  }
}
