<template>
  <DialogPanel
    class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:px-6 sm:pt-6 sm:pb-1"
  >
    <div>
      <div>
        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900"
          >Multiple files</DialogTitle
        >
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            You have selected multiple files - are they separate documents, or pages of the same
            document?
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-6">
      <FormKit type="form" @submit="uploadFiles" :actions="false">
        <FormKit
          type="button"
          :ignore="false"
          label="They are pages of the same document"
          @click="$emit('dismiss', { combinePages: true })"
        />
        <FormKit
          type="submit"
          :label="uploading ? 'Uploading' : 'They are all different documents'"
        />
      </FormKit>
    </div>
  </DialogPanel>
</template>

<script setup lang="ts">
import { supabase } from '@/lib/supabaseClient'
import router from '@/router'
import { DialogPanel, DialogTitle } from '@headlessui/vue'
import { useQueryClient } from '@tanstack/vue-query'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

const props = defineProps<{ files: File[] }>()
const emit = defineEmits(['dismiss'])

const uploading = ref(false)

const queryClient = useQueryClient()

const uploadFiles = async () => {
  uploading.value = true
  const workspaceId = router.currentRoute.value.params.workspaceId as string
  await Promise.all(
    props.files.map(async (file) => {
      const docId = uuidv4()
      const filePath = `${workspaceId}/${docId}/originals/${uuidv4()}`

      await supabase
        .from('Documents')
        .insert({
          id: docId,
          workspaceId,
          status: 'UPLOADING',
          originalFilePaths: [filePath]
        })
        .select()
        .single()

      await supabase.storage.from('documents').upload(filePath, file)

      await supabase
        .from('Documents')
        .update({
          status: 'UPLOADED'
        })
        .match({ id: docId, workspaceId })
    })
  )

  await queryClient.invalidateQueries(['workspaces', workspaceId, 'documents'])
  emit('dismiss', { combinePages: false })
  setTimeout(() => (uploading.value = false), 1000)
}
</script>
