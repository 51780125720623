<template>
  <div class="w-full @container">
    <div
      v-if="!context.value"
      class="relative w-full p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <span class="block text-sm font-semibold text-gray-900">Empty</span>
      </div>
    </div>
    <div
      v-else-if="isLoading"
      class="rounded-lg bg-white shadow h-24 flex items-center justify-center"
    >
      <IonSpinner />
    </div>
    <div
      v-else
      class="grid grid-cols-1 gap-4"
      :class="docs.length > 1 ? '@3xl:md:grid-cols-2 @3xl:lg:grid-cols-3' : ''"
    >
      <div class="w-full border border-gray-400 rounded-lg" v-for="doc of docs" :key="doc.id">
        <DocBox
          @click="previewDoc(doc)"
          class="cursor-pointer"
          :doc="doc"
          :key="context.value as string"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DocBox from '@/components/DocBox.vue'
import { useModalStore } from '@/stores/modal'
import { createMessage, type FormKitEvent, type FormKitNode } from '@formkit/core'
import { IonSpinner } from '@ionic/vue'
import { useQuery } from '@tanstack/vue-query'
import axios from 'axios'
import DocPreviewModal from './DocPreviewModal.vue'
import { useCertificateListQuery } from '@/queries/certificateList'
import { watch } from 'vue'
import type { DocumentData } from '@/types/types'
import { useRoute } from 'vue-router'

const { presentModal } = useModalStore()

const props = defineProps<{
  context: {
    node: FormKitNode
    docType?: string
    value?: string | string[]
    changeRequestId: string
    workspaceId?: string
    label?: string
  }
}>()

const multiple = props.context.node.name.split('|')[0] === 'ANY'

const route = useRoute()

const workspaceId = props.context.workspaceId || route.params.workspaceId

const previewDoc = (doc: DocumentData) => {
  presentModal({
    component: DocPreviewModal,
    componentProps: { doc }
  })
}

props.context.node.props.validationMessages = {
  required: 'This field is required',
  require_one: 'At least one of these docs is required'
}

props.context!.node.on('input', ({ payload }: FormKitEvent) => {
  if (!multiple) {
    toggleWarningMessage(false)
    if (!!payload && payload !== props.context?.docType) {
      toggleWarningMessage(true)
    }
  }
})

const { data: docs, isLoading } = useQuery({
  queryKey: ['changeRequestDocsPreview', props.context.changeRequestId],
  enabled: !!props.context.changeRequestId,
  queryFn: () =>
    axios.get(
      `${import.meta.env.VITE_API_BASE_URL}/w/${workspaceId}/changeRequests/${props.context.changeRequestId}/previewDocuments`,
      { params: { accessId: route.params.accessId } }
    ),
  select: ({ data }) => data
})

const { data: certificateTypes } = useCertificateListQuery()

watch(
  certificateTypes,
  (value) => {
    const label = props.context.label
    const docType = props.context.docType
    if (!!value && !label && !!docType) {
      const name = value.get(docType)?.name
      if (!!name) props.context.label = name
    }
  },
  { immediate: true }
)

const toggleWarningMessage = (show: boolean) => {
  if (show) {
    props.context!.node.store.set(
      createMessage({
        key: 'warning',
        blocking: false,
        value: show ? 'This doc may not be the correct type' : ''
      })
    )
  } else {
    props.context!.node.store.remove('warning')
  }
  // props.context!.node.props.help = show ? 'This doc may not be the correct type' : ''
}
</script>
