import { createApp } from 'vue'
import i18n from '@/i18n'
import { createPinia } from 'pinia'

import { IonContent, IonicVue, IonPage, IonRouterOutlet, IonSpinner } from '@ionic/vue'

import { plugin as FormkitPlugin, defaultConfig } from '@formkit/vue'
import config from '../formkit.config'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import { VueQueryPlugin } from '@tanstack/vue-query'

import App from '@/App.vue'
import router from '@/router'

import DateFormat from '@/components/DateFormat.vue'

import * as Sentry from '@sentry/vue'

import '@ionic/vue/css/core.css'

import '@/assets/main.css'
import getTranslations from '@/helpers/setLocale'
import { App as capacitorApp } from '@capacitor/app'
import type { URLOpenListenerEvent } from '@capacitor/app'

import '@pqina/pintura/pintura.css'

import { useAuthStore } from '@/stores/auth'
import DataStatusIndicator from '@/components/DataStatusIndicator.vue'
import CountrySelector from '@/components/CountrySelector.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import CurrencySelector from '@/components/CurrencySelector.vue'

import axios from 'axios'
import { supabase } from '@/lib/supabaseClient'
import { useModalStore } from '@/stores/modal'
import SelectTravelDoc from '@/components/SelectTravelDoc.vue'
;(window as any).apiUrl = import.meta.env.VITE_API_BASE_URL

// import { registerSW } from 'virtual:pwa-register'

// registerSW({ immediate: true })

axios.interceptors.request.use(async (config) => {
  const {
    data: { session }
  } = await supabase.auth.getSession()
  if (!!session) {
    config.headers['Authorization'] = `Bearer ${session.access_token}`
  }
  return config
})

const app = createApp(App)

app.use(createPinia())
app.use(IonicVue, { mode: 'md' })
app.use(router)
app.use(autoAnimatePlugin)
app.use(i18n)
app.use(VueQueryPlugin)

const hash = location.hash
if (hash.startsWith('#error')) {
  const { presentAlert } = useModalStore()
  const params = new URLSearchParams(hash)
  const title = params.get('error_description') || "That didn't work"
  presentAlert({
    title,
    message: 'Please try again or contact support if the problem persists'
  })
}

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://735cb462f4144fa484a300ed5f74aa4d@o4505238702194688.ingest.sentry.io/4505238703439872',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(FormkitPlugin, defaultConfig(config))

app.component('date-format', DateFormat)
app.component('CountrySelector', CountrySelector)
app.component('LanguageSelector', LanguageSelector)
app.component('CurrencySelector', CurrencySelector)
app.component('SelectTravelDoc', SelectTravelDoc)
app.component('ion-page', IonPage)
app.component('data-status-indicator', DataStatusIndicator)
app.component('ion-spinner', IonSpinner)
app.component('ion-content', IonContent)
app.component('ion-router-outlet', IonRouterOutlet)

const { setSession } = useAuthStore()

Promise.all([getTranslations(), router.isReady()]).then(([locale]) => {
  app.mount('#app')
  i18n.global.locale.value = locale as any
})

capacitorApp.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
  const openUrl = data.url
  const access = openUrl.split('#access_token=').pop()?.split('&')[0]!
  const refresh = openUrl.split('&refresh_token=').pop()?.split('&')[0]!
  await setSession(access, refresh)
})
