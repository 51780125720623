<template>
  <div v-if="multiple" class="w-full grid grid-cols-1 gap-4">
    <div
      class="w-full border border-gray-400 rounded-lg cursor-pointer"
      v-for="(docId, index) of context.value"
      :key="index"
    >
      <Menu as="template">
        <MenuButton as="template">
          <DocBox
            :doc="context.docsLibrary.get(docId)"
            @click="context.disabled && previewDoc(context.docsLibrary.get(docId))"
            class="cursor-pointer"
            :key="context.value as string"
          />
        </MenuButton>

        <transition
          v-if="!context.disabled"
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="previewDoc(context.docsLibrary.get(docId))"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  ]"
                  >View</a
                >
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  @click="chooseDoc"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Replace</a
                >
              </MenuItem>
            </div>
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="
                    props.context.node.input(
                      (context.value! as string[]).filter((id) => id !== docId)
                    )
                  "
                  :class="[
                    active ? 'bg-gray-100 text-red-800' : 'text-red-600',
                    'block px-4 py-2 text-sm'
                  ]"
                  >Remove</a
                >
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <div
      v-if="!context.disabled"
      @click="chooseDoc"
      class="relative cursor-pointer p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <DocumentPlusIcon class="mr-2 h-8 w-8 text-gray-400" />
        <span class="block text-sm font-semibold text-gray-900">Add or select documents</span>
      </div>
    </div>
    <div
      v-else-if="!context.value?.length"
      class="relative p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <span class="block text-sm font-semibold text-gray-900">Empty</span>
      </div>
    </div>
  </div>
  <div class="w-full" v-else>
    <div
      v-if="!context.value && !context.disabled"
      @click="chooseDoc()"
      class="relative cursor-pointer w-full p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <DocumentPlusIcon class="mr-2 h-8 w-8 text-gray-400" />
        <span class="block text-sm font-semibold text-gray-900">Add or select document</span>
      </div>
    </div>
    <div
      v-else-if="!context.value && context.disabled"
      class="relative w-full p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <span class="block text-sm font-semibold text-gray-900">Empty</span>
      </div>
    </div>

    <div class="w-full border border-gray-400 rounded-lg" v-else>
      <Menu as="template">
        <MenuButton
          :key="context.value as string"
          as="template"
          @vue:mounted="checkDocType(context.docsLibrary.get(context.value as string))"
        >
          <DocBox
            @click="
              context.disabled && previewDoc(context.docsLibrary.get(context.value as string))
            "
            :doc="context.docsLibrary.get(context.value as string)"
            class="cursor-pointer"
          />
        </MenuButton>

        <transition
          v-if="!context.disabled"
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="previewDoc(context.docsLibrary.get(context.value as string))"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  ]"
                  >View</a
                >
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  @click="chooseDoc"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Replace</a
                >
              </MenuItem>
            </div>
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="props.context.node.input(null)"
                  :class="[
                    active ? 'bg-gray-100 text-red-800' : 'text-red-600',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Remove</a
                >
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import addDocuments from '@/helpers/addDocuments'
import { DocumentPlusIcon } from '@heroicons/vue/24/outline'
import { useModalStore } from '@/stores/modal'
import SelectDocModal from '@/components/SelectDocModal.vue'
import DocBox from '@/components/DocBox.vue'
import type { DocumentData } from '@/types/types'
import { createMessage, type FormKitNode } from '@formkit/core'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useCertificateListQuery } from '@/queries/certificateList'
import { watch } from 'vue'
import DocPreviewModal from './DocPreviewModal.vue'

const props = defineProps<{
  context: {
    node: FormKitNode
    docType?: string
    docsLibrary: Map<string, any>
    crewProfileId: string
    disableTypeCheck?: true
    disabled: boolean
    workspaceId: string
    value?: string | string[]
    label?: string
  }
}>()

const previewDoc = (doc: any) => {
  presentModal({
    component: DocPreviewModal,
    componentProps: { doc }
  })
}

const multiple = props.context.node.name.split('|')[0] === 'ANY'

props.context!.node.on('input', () => {
  if (!multiple) {
    toggleWarningMessage(false)
  }
})

props.context.node.props.validationMessages = {
  required: 'This field is required',
  require_one: 'At least one of these docs is required'
}

const { data: certificateTypes } = useCertificateListQuery()

watch(
  certificateTypes,
  (value) => {
    const label = props.context.label
    const docType = props.context.docType
    if (!!value && !label && !!docType) {
      const name = value.get(docType)?.name
      if (!!name) props.context.label = name
    }
  },
  { immediate: true }
)

const { presentModal } = useModalStore()

const chooseDoc = async () => {
  const selectResult = await presentModal({
    component: SelectDocModal,
    componentProps: {
      title: props.context.label,
      preSelectedDocIds: !!props.context.value ? [props.context.value] : [],
      docType: props.context.docType,
      docsLibrary: props.context.docsLibrary,
      multiple: multiple,
      crewProfileId: props.context.crewProfileId
    }
  })

  if (!!selectResult) {
    if (selectResult.selectedDocs) {
      setValue(selectResult.selectedDocs)
    }
    if (selectResult.newDocFiles) {
      const finishedDocs = await addDocuments(selectResult.newDocFiles, {
        allowMultiple: false,
        crewProfileId: props.context.crewProfileId,
        workspaceId: props.context.workspaceId
      })
      if (!!finishedDocs) {
        finishedDocs.forEach((doc: DocumentData) => props.context.docsLibrary.set(doc.id, doc))
        if (!!multiple) {
          setValue(finishedDocs, true)
        } else {
          const matchingDoc = finishedDocs.find(
            ({ certificateTypeId }: any) => props.context.docType === certificateTypeId
          )
          setValue([!!matchingDoc ? matchingDoc : finishedDocs?.[0]])
        }
      }
    }
  }
}

const toggleWarningMessage = (show: boolean) => {
  if (!props.context.disableTypeCheck) {
    if (show) {
      props.context!.node.store.set(
        createMessage({
          key: 'warning',
          blocking: false,
          value: show ? 'This doc may not be the correct type' : ''
        })
      )
    } else {
      props.context!.node.store.remove('warning')
    }
  }
}

const setValue = (value: DocumentData[], merge?: boolean) => {
  const selectedDocIds = value.map(({ id }) => id)
  props.context!.node.input(
    multiple
      ? merge
        ? [...(props.context.value || []), ...selectedDocIds]
        : selectedDocIds
      : selectedDocIds[0]
  )
}

const checkDocType = async (doc: DocumentData | null) => {
  toggleWarningMessage(false)
  if (!!doc) {
    if (doc.categoryId !== props.context.docType && doc.certificateTypeId !== props.context.docType)
      toggleWarningMessage(true)
  }
}
</script>
