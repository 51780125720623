<template>
  <DialogPanel
    class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
  >
    <div class="sm:flex sm:items-start">
      <div
        :class="types[type].bg"
        class="relative mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
      >
        <div class="absolute inset-0 bg-white opacity-70"></div>
        <component
          :is="types[type].icon"
          class="h-6 w-6 relative"
          :class="types[type].text"
          aria-hidden="true"
        />
      </div>
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{
          title
        }}</DialogTitle>
        <div class="mt-2">
          <p class="text-sm text-gray-500">{{ message }}</p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        :class="types[type].bg"
        class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
        @click="$emit('dismiss', true)"
      >
        {{ confirmText || 'Continue' }}
      </button>
      <button
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        @click="$emit('dismiss', false)"
      >
        {{ cancelText || 'Cancel' }}
      </button>
    </div>
  </DialogPanel>
</template>

<script setup lang="ts">
import { DialogPanel, DialogTitle } from '@headlessui/vue'
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
defineProps<{
  title: string
  message: string
  type: 'info' | 'warn'
  confirmText?: string
  cancelText?: string
}>()

const types = {
  warn: {
    bg: 'bg-red-600',
    text: 'text-red-600',
    icon: ExclamationTriangleIcon
  },
  info: {
    bg: 'bg-primary-600',
    text: 'text-primary-600',
    icon: InformationCircleIcon
  }
}
</script>
