<template>
  <FormKit type="autocomplete" selection-removable="yes" :options="options" v-bind="props" />
</template>
<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import languages from '@/data/languages.json'

const options: any = languages.map((language) => ({ label: language.name, value: language.code }))
const props = defineProps<{ [key: string]: any }>()
</script>
